import { defineStore } from 'pinia'

export const groupUserStore = defineStore('groupUserStore', {
  state: () => {
    const teste = loadUser()
    return {
      ...teste,
    }
  },

  actions: {
    changeSelectedGroup(selectedGroup: any) {
      localStorage.setItem('selectedGroup', JSON.stringify(selectedGroup))
    },

    loadUser() {
      return loadUser()
    },
  },
})

export function loadUser() {
  const user = localStorage.getItem('selectedGroup')
  if (user) {
    return JSON.parse(user)
  }
  return null
}
